<template>
  <template v-if="listStyle === 'list'">
    <Accordion
      :title="title || 'Ohne Titel'"
      is-green
      :always-open="alwaysOpen"
    >
      <p v-if="text">{{ text }}</p>

      <a v-if="href" class="button is-link mb-20" :href="href" target="_blank">
        <span>{{ $texts('ctaExternalLink', 'Seite besuchen') }}</span>
        <span class="sr-only">
          {{
            $texts(
              'accessibility.externalLinkText',
              'Externer Link, wird in einem neuen Tab oder Fenster geöffnet',
            )
          }}
        </span>
      </a>

      <!-- Image -->
      <MediaImage
        v-if="image"
        v-bind="image"
        :image-style="imageStyle"
        img-class="w-full mb-20"
      />

      <!-- Downloads -->
      <ul v-if="downloadLinkList.length" class="mt-20">
        <li
          v-for="downloadlink in downloadLinkList"
          :key="downloadlink.uriPath"
        >
          <a
            target="_blank"
            :href="downloadlink.fileUrl"
            class="link with-icon"
          >
            {{ downloadlink.title }}
            <span class="sr-only">
              {{
                $texts(
                  'accessibility.externalLinkText',
                  'Externer Link, wird in einem neuen Tab oder Fenster geöffnet',
                )
              }}
            </span>
          </a>
        </li>
      </ul>

      <slot />
    </Accordion>
  </template>
  <template v-else-if="href">
    <TeaserDefault
      :title="title || ''"
      :to="href"
      :text="text"
      :media="image"
      to-node-bundle="filter_page_item"
    />
  </template>
  <div v-else>{{ title }}</div>
</template>

<script lang="ts" setup>
import type {
  FieldItemTypeLinkDescriptionFragment,
  ParagraphFilterPageItemFragment,
} from '#build/graphql-operations'
import { notNullish } from '@vueuse/core'

const props = defineProps<{
  id?: string
  title?: ParagraphFilterPageItemFragment['title']
  text?: ParagraphFilterPageItemFragment['text']
  link?: ParagraphFilterPageItemFragment['link']
  paragraphs?: ParagraphFilterPageItemFragment['paragraphs']
  image?: ParagraphFilterPageItemFragment['image']
  downloadLinks?: ParagraphFilterPageItemFragment['downloadLinks']
  alwaysOpen?: boolean
  listStyle?: 'list' | string
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  sizes: {
    sm: 400,
    md: 688,
    lg: 756,
    xl: 1000,
  },
})

function getDownloadUrl(
  v?: FieldItemTypeLinkDescriptionFragment,
): string | undefined {
  if (v && v.uri && 'entity' in v.uri && v.uri.entity) {
    // Use originalFileUrl for documents.
    if ('originalFileUrl' in v.uri.entity && v.uri.entity.originalFileUrl) {
      return v.uri.entity.originalFileUrl
    }

    // Use Media file url.
    if ('mediaFileUrl' in v.uri.entity && v.uri.entity.mediaFileUrl?.path) {
      return v.uri.entity.mediaFileUrl.path
    }
  }
}

const downloadLinkList = computed(() =>
  (props.downloadLinks || [])
    .map((downloadLink) => {
      const fileUrl = getDownloadUrl(downloadLink)

      if (!fileUrl) {
        return null
      }

      return {
        title: downloadLink.linkTitle,
        fileUrl,
        uriPath: downloadLink.uri?.path,
      }
    })
    .filter(notNullish),
)

const href = computed(() => props.link?.uri?.path)
</script>
